.privacy-policy h2{
    font-style: normal;
    font-weight: 400;
    font-size: 45px !important;
    line-height: 90px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #0C2D24 !important;
    margin: 0;
}

.privacy-policy p{
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    color: #0C2D24;
    padding: 0;
}