
@font-face {
	font-family: "Test Söhne Breit";
	src: url("./assets/fonts/testso-hnebreit-kra-ftig.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
  }
  
  /**
   * Proxima Nova
   */
  @font-face {
	font-family: "Proxima Nova";
	src: url("./assets/fonts/proximanova-regular.woff2") format("woff2");
	font-weight: 400;
	font-style: normal;
  }
  
  @font-face {
	font-family: "Proxima Nova";
	src: url("./assets/fonts/proximanova-semibold.woff2") format("woff2");
	font-weight: 600;
	font-style: normal;
  }
  
  @font-face {
	font-family: "soehne-breit-dreiviertelfett";
	src: url("./assets/fonts/soehne-breit-dreiviertelfett.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
  }
  
  @font-face {
	font-family: "soehne-breit-kraftig";
	src: url("./assets/fonts/soehne-breit-kraftig.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
  }
  
  /* .top-banner{
	  background: url(./assets/img/body-bg.png)no-repeat;
	  background-size: cover; 
  } */
  
  ul{
	  margin: 0;
	  padding: 0;
	  list-style: none;
  }
  
  .btn,
  .form-control{
	  box-shadow: none !important;
	  outline: none !important;
  }
  
  .navbar-toggler:not(:disabled):not(.disabled) {
	  box-shadow: none;
	  outline: none;
  }
  
  .title span{
	  color: #06C290;
  }
  
  /*.header{
	  padding-top:20px;
  }*/
  
  .container{
	max-width: 90%!important;
  }
  
  .w-70{
	  width: 70px;
  }
  
  .w-60{
	  width: 60px;
  }

  .w-85{
	width: 85px;
   }
  
  /* .radial-gradient {
	position:fixed;
	top:0px;
	left:0px;
	height:100%;
	width:100%;
	z-index: -1;
  } */
  
   .header{
	  padding-top:20px;
	  margin-bottom: 100px;
  }
  
  .navbar .navbar-nav .nav-item .nav-link{
	font-family: "Test Söhne Breit";
	font-size: 18px;
	line-height: 120%;
	text-align: right;
	letter-spacing: 0.04em;
	text-transform: uppercase;
	color: #2E3939;
	margin: 19px 28px;
	padding: 0 0 4px 0;
	border-bottom: 2px solid;
	border-color: transparent;
  }
  
  
  .header .navbar-brand img{
	  width: 220px;
	  margin-left: 15px;
  }
  
  .navbar .navbar-nav .nav-item .nav-link:hover{
	color: #06C290;
    border-bottom: 2px solid #06C290;
  }
  .navbar-nav{
	z-index: 999999;
  }
  
  
  .top-banner{
	  position: relative;
  }
  
  #gradient-canvas {
	  --gradient-color-1: #ffffff;
	  --gradient-color-2: #8DFBC8;
	  /*--gradient-color-3: #C2F268;*/
	  /*--gradient-color-4: #FBFD38;*/
  }
  
  #gradient-canvas{
	  position: absolute;
	  width: 100%;
	  height: 100%;
	  left:0;
	  right: 0;
	  top:0;
	  bottom: 0;
  }
  
  
  #meet-react-gradient-canvas {
	  --gradient-color-1: #ffffff;
	  --gradient-color-2: #8DFBC8;
	  /* --gradient-color-3: #C2F268; */
	  --gradient-color-3: #FBFD38;
	  --gradient-color-4: #ffffff;
	  --gradient-color-5: #ffffff;
  }
  
  #meet-react-gradient-canvas{
	  position: absolute;
	  width: 100%;
	  height: 100%;
	  left:0;
	  right: 0;
	  top:0;
	  bottom: 0;
  }
  
  
  #feature-focused-gradient-canvas{
	  --gradient-color-1: #ffffff;
	  --gradient-color-2: #8DFBC8;
	  /* --gradient-color-3: #C2F268;
	  --gradient-color-4: #FBFD38; */
	  --gradient-color-3: #FBFD38;
	  --gradient-color-4: #ffffff;
  }
  
  #feature-focused-gradient-canvas{
	  position: absolute;
	  width: 100%;
	  height: 100%;
	  left:0;
	  right: 0;
	  top:0;
	  bottom: 0;
	  z-index: -2;
  }


  #faq-gradient-canvas {
	  --gradient-color-1: #ffffff;
	  --gradient-color-2: #8DFBC8;
	  --gradient-color-3: #ffffff;
	  --gradient-color-4: #FBFD38;
  }
  
  #faq-gradient-canvas{
	  position: absolute;
	  width: 100%;
	  height: 100%;
	  left:0;
	  right: 0;
	  top:0;
	  bottom: 0;
	  z-index: -9;
  }
  
  
  .top-banner .top-banner-left{
	  padding-top: 100px;
	  text-align: left;
  }
  
  .top-banner .top-banner-left h1{
	  font-family: "Test Söhne Breit";
	  font-size: 75px;
	  line-height: 95px;
	  letter-spacing: 0.04em;
	  color: #0C2D24;
	  margin: 0;
	  padding-bottom: 58px;
	  text-transform: capitalize;
	  font-weight: 600;
	  max-width: 56%;
	 
  }
  .text-animation{
	animation: fadeIn 1s ease-in both;
	animation-delay: 3s;
  }
  .text-animation2{
	animation: fadeIn 1s ease-in both;
	animation-delay: 4s;
  }
  .text-animation3{
	animation: fadeIn 1s ease-in both;
	animation-delay: 5s;
  }
  @keyframes fadeIn {
	from {
		opacity: 0;
		transform: translate3d(0, -20%, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}
  @-webkit-keyframes fadeIn {
	0% {
	   opacity: 0;
	   -webkit-transform: translateY(-20px);
	}
	100% {
	   opacity: 1;
	   -webkit-transform: translateY(0);
	}
 }
  .top-banner .top-banner-left h1 span{
		background-color: #0c2d24;
		color: #f8ff31;
		padding: 0 5px;
		margin: 0 15px;
  }
  
  .top-banner .top-banner-left p{
	  font-family: "Proxima Nova";
	  font-style: normal;
	  font-weight: 400;
	  font-size: 30px;
	  line-height: 130%;
	  color: #2E3939;
	  margin: 0;
	  padding-bottom: 56px;
	  max-width: 580px;
 }
  
  .wailist-input{
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
	  background: rgba(255, 255, 255, 0.77);
	  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
	  border-radius: 100px;
	  padding: 6px;
	  max-width: 575px;
	  margin: 0 auto;
  }

  .top-banner-bg .error{
	margin-bottom: 150px;
    justify-content: space-between;
    align-items: center;
    padding: 12px 12px 12px 40px !important;
    max-width: 615px;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
	margin: 0 auto;

  }
  
  .footer .footer-input .wailist-input{
	background: #103E31;
	max-width: 80%;
	margin: 0;
  }

  
  .wailist-input .form-control{
	  border: none;
	  font-family: "Proxima Nova";
	  font-style: normal;
	  font-weight: 400;
	  font-size: 16px;
	  line-height: 120%;
	  display: flex;
	  align-items: center;
	  letter-spacing: 0.01em;
	  color: #2E3939;
	  background-color: transparent !important;
	  z-index: 999999;

  }
  
  
  .wailist-input .btn {
	  background: linear-gradient(90deg, #06C290 0%, #95E5D0 33.85%, #E1F5C7 66.67%, #F8FF31 98.96%) !important;
	  border-radius: 100px;
	  text-align: center;
	  letter-spacing: 0.04em;
	  text-transform: uppercase;
	  padding: 15px 28px;
	  font-size: 18px;
      font-weight: 600;
	  z-index: 999999;
	  color: #0C2D24 !important;
	  border: none;		
  }
  
  .wailist-input .btn:hover{
	  background: #F8FF31;
	  border-color: #F8FF31;
	  color: #2E3939;
  }
  
  
  .wailist-input .form-control::placeholder {
	  font-family: "Proxima Nova";
	  font-style: normal;
	  font-weight: 400;
	  font-size: 22px;
	  letter-spacing: 1%;
	  color: #2E3939;
	  opacity: 1; /* Firefox */
  }
  
  .wailist-input .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
	  font-family: "Proxima Nova";
	  font-style: normal;
	  font-weight: 400;
	  font-size: 22px;
	  letter-spacing: 1%;
	  color: #2E3939;
  }
  
  .wailist-input .form-control::-ms-input-placeholder { /* Microsoft Edge */
	  font-family: "Proxima Nova";
	  font-style: normal;
	  font-weight: 400;
	  font-size: 22px;
	  letter-spacing: 1%;
	  color: #2E3939;
  }

  .top-banner .top-banner-right{	
	position: relative;	
	text-align: center;	
	}	

	.top-banner .top-banner-right .globe-box img{	
		width: 315px;	
	}
  
  /* .top-banner .top-banner-right{
	  position: relative;
  } */
  
  .top-banner .top-banner-right .element-1,
  .top-banner .top-banner-right .element-2{
	  position: absolute;
  }
  
  .top-banner .top-banner-right .element-1{
	  left: 84px;
	  top: -14px;
	  transform: rotate(-14deg);
  }
  
  .top-banner .top-banner-right .element-1 .avatar-1{
	  margin: -85px 0 0 -120px;
	  transform: rotate(9deg);
  }
  
  .top-banner .top-banner-right .element-1 .avatar-3{
	  margin: 135px 0 0 -50px;
	  transform: rotate(18deg);
  }
  
  .top-banner .top-banner-right .element-1 .avatar-2{
	  margin: -55px 0 0 -55px;
	  transform: rotate(18deg);
  }
  
  
  .top-banner .top-banner-right .element-2{
	  right: 25px;
	  bottom: 30px;
	  transform: rotate(-19deg);
  }
  
  .top-banner .top-banner-right .element-2 .avatar-5{
	  margin: -90px 0 0 0;
	  transform: rotate(15deg);
  }
  
  .top-banner .top-banner-right .element-2 .avatar-4{
	  margin: 25px -98px 0 -54px;
	  transform: rotate(18deg);
  }
  
  .top-banner .top-banner-right .element-2 .avatar-6{
	  margin: -108px 0 0 35px;
	  transform: rotate(10deg);
  }
  
  .changing-world{
	  /*padding: 150px 0 250px 0;*/
	  padding: 100px 0;
  }
  
  
  .changing-world h2{
	  font-family: "Test Söhne Breit";
	  /*font-size: 68px;*/
	  font-size: clamp(34px,4.17vw,80px);
	  line-height: 120%;
	  text-align: center;
	  letter-spacing: 0.04em;
	  color: #0C2D24;
	  margin-bottom: 2.5em;
	  /*margin-bottom: 2em;*/
  }
  
  .changing-world ul.large{
	  margin-bottom: 100px;
  }
  
  .changing-world ul.large li img{
	  width: 21.4vw;
  }
  
  .changing-world ul.large li .hover-img {
	  position: absolute;
	  top: 0;
	  left: 14%;
  }
  
  .changing-world ul.large li .hover-img{
	  opacity: 0;
	  filter: drop-shadow(0px 0px 4px #888);
  }
  
  .changing-world ul.large li:hover .with-out-hover{
	  opacity: 0;
  }
  
  .changing-world ul.large li:hover .hover-img{
	  opacity: 1;
  }
  
  
  .changing-world ul.small{
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
  }
  
  
  .changing-world ul.small li{
	  position: relative;
  }
  .changing-world ul.small li img{
	  width: 90%;
  }

  .changing-world ul li{
	z-index: 999999;
  }
  
  
  
  .changing-world ul.small li .hover-img {
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 90% !important;
  
  }
  
  .changing-world ul.small li .hover-img{
	  opacity: 0;
	  filter: drop-shadow(0px 0px 4px #888);
  }
  
  .changing-world ul.small li:hover .with-out-hover{
	  opacity: 0;
  }
  
  .changing-world ul.small li:hover .hover-img{
	  opacity: 1;
  }
  
  .marquee-text {
	  font-family: "Test Söhne Breit";
	  /*font-size: 45px;*/
	  font-size: 28px;
	  line-height: 120%;
	  text-align: center;
	  letter-spacing: 0.04em;
	  text-transform: uppercase;
	  border: 2px solid rgba(12, 45, 36, 0.75);
	  /*padding: 12px;*/
	  padding: 22px;
	  overflow-y: hidden!important;
	  background: #EAFFCE;
}
  
  .massive-impact-content{
	  border: 2px solid #2E3939;
	  padding: 65px;
  
  }
  .massive-impact-bg-shap{
	  /* background: url(./assets/img/massive-impact-vector-shap.png)no-repeat; */
	  /*padding:72px 0 200px 0;*/
	  padding:75px 0 75px 0;
  }
  
  
  .massive-impact .title{
	  font-family: "Test Söhne Breit";
	  /*font-size: 40px;*/
	  font-size: clamp(34px,3.13vw,60px);
	  line-height: 120%;
	  letter-spacing: 0.04em;
	  text-transform: capitalize;
	  color: #0C2D24;
	  padding-bottom: 58px;
  }
  
  .massive-impact .massive-impact-content p{
	  font-family: "Proxima Nova";
	  font-style: normal;
	  font-weight: 400;
	  /*font-size: 28px;*/
	  font-size: 22.5px;
	  line-height: 130%;
	  color: #2E3939;
  }
  
  /* .massive-impact-right{
	  padding-top: 20%;
	  padding: 20% 50px 0 60px
  } */
  
  /* .fixing-planet .massive-impact-right{
	  padding: 20% 50px 0 0;
  } */
  
  .fixing-planet{
	padding-bottom: 100px;
  }

  .fixing-planet .massive-impact-right{
	  padding: 7% 50px 0 0;
  }
  .future-focused .container {
	  max-width: 80% !important;
  }
  
  .future-focused{
	  padding:0 200px;
	  position: relative;
  }
  .energy-together-content{
		background: #E1F5C7;
		border-radius: 40px;
		padding: 100px 65px 50px 65px;
  }

  .future-focused h2{
	  padding-bottom:50px;
	  font-family: "Test Söhne Breit";
	  line-height: 120%;
	  text-align: left;
	  letter-spacing: 0.04em;
	  color: #0C2D24;
	  font-size: clamp(34px, 3.13vw, 60px);
  }


  .energy-together-content .left-contnt h2 span{
	color: #0C2D24;
	background-color: #f8ff31;
	display: inline-block;
	letter-spacing: 0.04em;
	line-height: 50px;
	font-size:45px;
	margin-bottom: 40px;
  }
  
  
  .future-focused{
	  /* background: url(./assets/img/future-focused-gradient.png)no-repeat; */
	  /*padding: 200px 0 265px 0;*/
	  padding: 200px 0 50px 0;
	  background-size: cover;
	  background-position: bottom;
	  background-color:#0C2D24;
  }
  .future-focused .card {
	  padding: 20px 30px 10px 30px;
	  border: none;
	  background: #0C2D24;
	  border-radius: 20px;
	  margin-bottom: 25px !important;
  }
  
  .future-focused .card h3{
		font-family: "Proxima Nova";
		font-weight: 400;
		font-size: 22px;
		line-height: 150%;
		color: #fff;
		padding-bottom:10px;
		margin: 0;
  }

  .future-focused .energy-together-content .right-content{
	z-index: 999;
    position: relative;
  }
   
  .future-focused .card p{
	  font-family: "Proxima Nova";
	  font-style: normal;
	  font-weight: 400;
	  font-size: 18px;
	  line-height: 28.8px;
	  color: #fff;
  }


  .future-focused .card p a{
	color: #fff;
	border-bottom: 1px solid #fff;
	text-decoration: none;
	z-index: 9999;
	position: relative;
  }

 
  
  
  .fixing-planet .massive-impact-bg-shap {
	  background: no-repeat;
  }
  
  .fixing-planet .massive-impact-content {
	  border: none;
	  padding: 0;
  }
  
  /* .fixing-planet .massive-impact-right p{
	  width: 75%;
  } */
  
  .fixing-planet .massive-impact-left{
	text-align: end;
  }
  
  .meet-network{
	  position: relative;
	  /* background: url(./assets/img/react-network.png)no-repeat 0 -10px; */
	  padding: 0;
	  background-size: cover;
  
  }
  .meet-network h2{
	  font-family: "Test Söhne Breit";
	  /*font-size: 68px;*/
	  font-size: clamp(34px,4.17vw,80px);
	  line-height: 120%;
	  letter-spacing: 0.04em;
	  text-transform: capitalize;
	  color: #2E3939;
	  padding-bottom: 100px;
  }
  
  .meet-network ul li{
	  display: flex;
	  justify-content: flex-start;
	  align-items: flex-start;
	  padding: 47px 0 113px 0;
	  border-top: 2px solid #2E3939;
	  transition: visibility 0.5s, opacity 0.5s linear;
	  
  }
  
  .meet-network ul li a{
	  text-decoration: none;
  }
  
  .meet-network ul li h3{
	  font-family: "Test Söhne Breit";
	  font-size: 38px;
	  line-height: 120%;
	  letter-spacing: -0.01em;
	  color: #2E3939;
	  padding-bottom: 48px;
  }
  
  .meet-network ul li p{
	  font-family: "Proxima Nova";
	  font-style: normal;
	  font-weight: 400;
	  font-size: 22.5px;
	  line-height: 130%;
	  color: #0C2D24;
  }
  
  .meet-network ul li a p{
	  opacity: 0;
	  transition: height 0.7s;
	  transition: visibility 0.5s, opacity 0.5s linear;
	  height: 0;
  }
  .meet-network ul li.active a p{
	  opacity: 1;
	  transition: height 0.7s;
	  transition: visibility 0.5s, opacity 0.5s linear;
	  height: auto;
  }
  
  .meet-network ul li .img-box{
	  margin-right:70px; 
  }
  
  .meet-network ul li.active h3{
	  color: #06c290;
  }
  
  .meet-network ul li.active .img-box svg{
	  fill: #06c290;
  }
  
  
  .footer{
	  background: #0C2D24;
	  border-radius: 0px;
  }
  
  .footer h2{
	  font-family: "Test Söhne Breit";
	  /*font-size: 80px;*/
	  /*font-size: 68px;*/
	  font-size: 50px;
	  line-height: 120%;
	  letter-spacing: 0.04em;
	  text-transform: capitalize;
	  color: #FFFFFF;
	  padding: 94px 0 54px 0;
  }
  
  .footer .form-control{
	  background-color: transparent !important;
	  color: #fff;
  }
  
  
  .footer .form-control::placeholder {
	  color: #fff;
  }
  
  .footer .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
	  color: #fff;
  }
  
  .footer .form-control::-ms-input-placeholder { /* Microsoft Edge */
	  color: #fff;
  }
  
  .footer .footer-input{
	  margin-bottom: 150px;
  }
  
  .footer .social-box{
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
  }
  
  .footer .social-box ul{
	  display: flex;
	  justify-content: flex-end;
	  align-items: center;
	  position: fixed;
	  bottom: 60px;
	  right: 6.7vw;
	  z-index: 999999;
  }
  .footer .social-box ul li{
	  margin:0 14px;  
  }
  
  .footer .social-box ul li a img{
	  filter: drop-shadow(0px 2px 5px #000);
  }
  
  
  .footer .social-box{
	  margin-bottom: 50px;
  }
  
  /*.meet-network .images .dot:first-of-type {
	  width: 4.2vw !important;	
	  height: 4.2vw !important;
  }*/
  
  .meet-network .images .dot{
	  position: relative;
	  flex-shrink: 0;
	  width: 47px;
	  height: 47px;
	  border-radius: 100%;
	  background-color: #0c2d24;
	  transition: background-color .5s,width .5s,height .5s;
	  margin: 3vw auto;
  }
  
  .meet-network .images .dot.first-dot{
	  width: 4.2vw;
	  height: 4.2vw;
  }
  
  .meet-network .images{
	  display: flex;
	  flex-direction: column;
	  align-items: center;
	  justify-content: center;
	  position: sticky;
	  top: 0;
	  height: 100vh;
	  margin-right: 6.7vw;
  }
  
  .meet-network .images:before{
	  content: "";
	  display: block;
	  position: absolute;
	  width: 3px;
	  height: 100%;
	  background-color: #0c2d24;
	  left: 50%;
	  transform: translateX(-50%);
  }
  
  
  .meet-network .images .dot.expanded{
	  background-color: #06c290;
	  width: 4.2vw;
	  height: 4.2vw;
  }
  
  .meet-network .images .image{
	  position: relative;
	  width: 31.2vw;
	  height: 0;
	  overflow: hidden;
	  z-index: 1;
	  transition: height 0.7s;
  }
  
  .meet-network .images .image.expanded{
	  height: 20vw;
  }
  
  
  .meet-network .images .image img{
	  display: none !important;
  }
  
  .meet-network .images .image.expanded img{
	  /*height: 29.2vw;*/
	  height: 18vw;
	  display: block !important;
  }
  .meet-network .images .image img{
	  position: absolute;
	  display: block;
	  width: 100%;
	  height: 100%;
	  -o-object-fit: cover;
	  object-fit: cover;
	  left: 0;
  }
  
  .meet-network .images .image.last {
	  margin-bottom: 3vw;
  }
  
  .meet-network h2{
	  margin-top: 150px;
  }
  
  #meet-network-list{
	  margin-bottom:150px;
  }
  
  .error{
    padding: 28px;
}
div.error2 {
    color: #fff;
    margin-bottom: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 12px 12px 28px;
    max-width: 615px;
	font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
}

.top-banner-left span.success{
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #0C2D24;
}

div.success2 {
    color: #fff;
    margin-bottom: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 12px 12px 0px;
    max-width: 615px;
	font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;

}
.footer .social-box ul li a img{
	width: 30px;
  }
  @media (min-width: 1500px){
	  .top-banner .top-banner-right .element-1{
		  left: 35px;
		  top: -50px;
	  }
  
	  .top-banner .top-banner-right .element-2 {
		  right: -15px;
		  bottom: -6px;
		  transform: rotate(-19deg);
	  }
  
  }
  
  @media (max-width: 1500px){
  
	  .future-focused .container {
		  max-width: 90% !important;
	  }
	  .top-banner .top-banner-right .element-1{
		  left: 0px;
		  top: -60px;
		  transform: rotate(-18deg);
	  }
  
	  .top-banner .top-banner-right .element-2 {
		  right: 0;
		  bottom: -45px;
		  transform: rotate(-19deg);
	  }
  
	  .top-banner .top-banner-right .element-1 .avatar-3{
		  width: 145px;
	  }
  
  
	  .top-banner .top-banner-right .element-1 .avatar-1{
		  width: 144px;
		  left: 0px;
		  top: -40px;
	  }
  
  
	  .top-banner .top-banner-right .element-1 .avatar-2{
		  width: 150px;
		  margin:-42px 0 0 -30px;
	  }
  
  
	  .top-banner .top-banner-right .element-2 .avatar-4{
		  width: 150px;
	  }
  
	  .changing-world h2
	  {
		  font-size: 70px;
	  }
  
	  .meet-network h2{
		  font-size: 56px;
		  padding-bottom: 90px;
		  margin-top: 150px;
	  }
  
	  .massive-impact .title{
		  padding-bottom: 36px;
	  }
  
	  .massive-impact-right {
		  padding-top: 10%;
	  }
  
  
	  .massive-impact .massive-impact-content p{
		  font-size: 24px;
	  }
  
  	  .future-focused .card h3{
		  font-size: 30px;
	  }
  
  
	  .future-focused .card p {
		  font-size: 20px;
	  }
  
	  .meet-network ul li{
		  padding: 47px 0 90px 0;
	  }
  }
  
  @media (max-width: 1200px){
	  .top-banner .top-banner-left h1{
		  font-size: 58px;
	  }
  
  
	  .top-banner .top-banner-left {
		  padding-top: 80px;
	  }
  
  
	  .top-banner .top-banner-left p{
		  font-size: 26px;
	  }
  
	  .wailist-input .btn{
		  padding: 12px;
		  font-size: 14px;
	  }
  
	  .wailist-input .form-control::placeholder,
	  .footer .wailist-input .form-control::placeholder {
		  font-size: 16px;
	  }
  
	  .wailist-input .form-control:-ms-input-placeholder,
	  .footer .wailist-input .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
		  font-size: 16px;
	  
	  }
  
	  .wailist-input .form-control::-ms-input-placeholder,
	  .footer  .wailist-input .form-control::-ms-input-placeholder { /* Microsoft Edge */
		  font-size: 16px;
	  }
  
	  .changing-world h2, .future-focused h2{
		  font-size: 48px;
	  }
  
	  .future-focused h2{
		  padding-bottom: 80px;
	  }
  
	  .massive-impact .marquee-text{
		  font-size: 24px;
		  padding:22px 0;
	  }
  
	  .changing-world {
		  padding: 100px 0 150px 0;
	  }
  
	  .massive-impact-right {
		  padding-top: 0;
	  }
  
	  .massive-impact .title{
		   font-size: 34px;
	  }
  
	  .massive-impact .massive-impact-content p {
		  font-size: 20px;
	  }
  
	  .future-focused{
		  padding: 100px 0 150px 0;
	  }
  
	  .future-focused .card{
		  padding: 25px;
	  }
  
	  /* .future-focused .card img{
		  width: 45px;
	  } */
  
	  .future-focused .card p{
		  font-size: 20px;
	  }
  
	  .future-focused .card h3 {
		  font-size: 20px;
	  }
  
	  .massive-impact-right{
		  padding: 20% 0 0 0
	  }
  
  
  
	  .meet-network h2 {
		  font-size: 42px;
	  }
  
	  .meet-network ul li h3{
		  font-size: 22px;
	  }
  
	  .meet-network ul li p{
		  font-size: 20px;
	  }
  
	  .meet-network ul li{
		  padding: 47px 0 50px 0;
	  }
  
  
	  .meet-network ul li .img-box{
		  margin-right: 35px;
	  }
	  .meet-network ul li .img-box img{
		  width: 46px;
	  }
  
	  .footer h2{
		   font-size: 42px;
	  }
  }
  
  @media (max-width: 991px){
	  .changing-world h2, .future-focused h2 {
		  font-size: 38px;
	  }
	  .header {
		  padding: 0;
		  margin-bottom: 40px;
	  }
  
	  .top-banner{
		  /* background: url(./assets/img/body-bg.png)no-repeat 0 462px; */
		  background-size: cover;
	  }
  
	  .top-banner .row.direction{
		  flex-direction: column-reverse;
	  }
  
	  .top-banner .row.direction .col-md-6{
		  max-width: 100%;
	  }
  
  
	  .massive-impact .massive-impact-content .row{
		  flex-direction: column;
	  }
  
	  .massive-impact .massive-impact-content .row .col-md-6{
		  max-width: 100%;
	  }
  
  
	  .massive-impact .massive-impact-content .massive-impact-left{
		  margin: 0 0 40px 0;
	  }
  
	  .massive-impact-bg-shap{
		  padding: 70px 0 100px 0;
	  }
  
	  .massive-impact-right{
		  /*padding-top: 20%;*/
		  padding: 0
	  }
  
  
	  .future-focused .card{
		  gap: 18px;
	  }
  
	  .future-focused .card h3{
		  font-size: 18px;
		  padding-bottom: 20px;
	  }
  
	  .future-focused .card p {
		  font-size: 16px;
	  }
  
	  .future-focused .card {
		  min-height: 290px;
	  }
	  
	  .future-focused {
		  padding: 75px 0 78px 0;
	  }
  
	  .meet-network h2 {
		  font-size: 32px;
	  }
  
  
	  .footer h2 {
		  font-size: 30px;
	  }
  
	  .navbar .navbar-nav .nav-item .nav-link{
		  text-align: left;
	  }
  
	  .wailist-input .form-control::placeholder,
	  .footer .wailist-input .form-control::placeholder {
		  font-size: 16px;
	  }
  
	  .wailist-input .form-control:-ms-input-placeholder,
	  .footer .wailist-input .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
		  font-size: 16px;
	  
	  }
  
	  .wailist-input .form-control::-ms-input-placeholder,
	  .footer  .wailist-input .form-control::-ms-input-placeholder { /* Microsoft Edge */
		  font-size: 16px;
	  }
  
	  .footer .row .col-md-7{
		  max-width: 100%;
		  min-width: 100%;
	  }
  }
  
  @media (max-width: 767px){
	  .top-banner .top-banner-left h1{
		  padding-bottom: 15px;
	  }
  
	  .top-banner .top-banner-left p{
		  padding-bottom: 1.5em;
	  }
  
	  .changing-world ul.small li {
		  position: relative;
		  margin: 0 0 50px 0;
		  text-align: center;
	  }
	  .changing-world ul.large li img {
		  width: 90%;
		  margin: 0 10px 50px 10px;
	  }
  
	  .changing-world ul.small{
		  flex-direction: column;
	  }
  
	  .changing-world ul.large li a br{
		  display: none;
	  }
	  .changing-world ul.large {
		  margin-bottom: 30px;
	  }
	  .future-focused{
		  background-position: 0 -265px;
	  }
	  .future-focused .card {
		  gap: 30px;
		  min-height: auto;
		  max-width: 500px;
		  margin: 15px auto !important;
	  }
  
	  .future-focused .card h3 {
		  font-size: 30px;
	  }
  
	  .future-focused .card p {
		  font-size: 20px;
	  }
  
	  #image-div{
		  display: none;
	  }
  
	  .meet-network h2 {
		  text-align: center;
		  padding-bottom: 80px;
		  margin-top: 80px;
		  line-height: 40px;
	  }
  
	  .meet-network ul li a p {
		  opacity: 1;
		  height: auto;
	  }
  
	  .changing-world ul li img{
		  width: 70% !important;
	  }
  
	  .changing-world ul.small li .hover-img,
	  .changing-world ul.large li:hover .hover-img{
		  display: none;
	  }
  
	  .changing-world ul.small li:hover .with-out-hover,
	  .changing-world ul.large li:hover .with-out-hover{
		  opacity: 1;
	  }
  
	  .massive-impact .marquee-text{
		  margin-bottom: 50px;
	  }
  
	  .massive-impact-bg-shap {
		  padding: 70px 0 50px 0;
	  }
  
	  .meet-network ul li{
		  flex-direction: column;
	  }
  
	  #meet-network-list {
		  margin-bottom: 0;
	  }
  
	  .meet-network ul li h3{
		  padding: 0.5em 0 1em;
		  line-height: 32px;
	  }
  
	  .meet-network ul li.active h3 {
		  color: #2E3939;
	  }
  
	  .meet-network ul li.active .img-box svg {
		  fill: #2E3939;
	  }
	  .footer h2{
		  padding: 50px 0 35px 0;
		  line-height: 40px;
	  }
  }
  
  @media (max-width: 576px){
	  .changing-world {
		  padding: 100px 0;
	  }
  
	  .wailist-input .btn{
		  width: 100%
	  }
  
	  .header .navbar-brand img {
		  margin-left: 0;
	  }	
  
	  .wailist-input,
	  .footer .wailist-input{
		  flex-direction: column;
		  background: none;
		  box-shadow: none;
		  border-radius: 0;
	  }
  
	  .footer .wailist-input .form-control{
		  border:none !important;
	  }
  
	  .wailist-input .form-control,
	  .footer .wailist-input .form-control{
		  background: radial-gradient(100% 359.18% at 0% 0%, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.2) 100%);
		  box-shadow: 0px 20px 40px rgb(0 0 0 / 10%);
		  border-radius: 100px;
		  border:1px solid #ddd;
		  margin-bottom: 20px;
		  height: 50px;		
	  }
  
  
  
	  .top-banner .top-banner-right .element-1 .avatar-1{
		  width: 80px;
		  margin: -46px 0 0 -50px;
	  }
  
	  .top-banner .top-banner-right .element-1 .avatar-2{
		  width: 90px;
		  margin: 0 0 0 -24px;
	  }
	  .top-banner .top-banner-right .element-1 .avatar-3 {
		  width: 90px;
		  margin: 48px 0 0 -14px;
	  }
  
	  .top-banner .top-banner-right .element-2 .avatar-5{
		  margin: -35px 25px 0 0;
		  width: 80px;
	  }
  
  
	  .top-banner .top-banner-right .element-2 .avatar-4{
		  width: 80px;
		  margin: 25px -98px 0 -54px;
	  }
  
	  .top-banner .top-banner-right .element-2 .avatar-6{
		  margin: -24px 0 0 66px;
		  width: 100px;
	  }
	  .container {
		  max-width: 100%;
	  }
  
	  /* .header .navbar-brand img{
		   width: 180px;
	  } */
  
  
	  .top-banner .top-banner-left h1 {
		  font-size: 40px;
	  }
  
	  .massive-impact-content {
		  border: 2px solid #2E3939;
		  padding: 15px;
	  }
  
  
	  .massive-impact .title {
		  font-size: 28px;
		  padding-bottom: 15px;
		  line-height: 36px;
	  }
  
	  .fixing-planet .massive-impact-right p {
		  width: 100%;
	  }
  
	  .future-focused h2 {
		  padding-bottom: 20px;
	  }
  
  
	  .future-focused .card h3 {
		  font-size: 24px;
	  }
	  .footer h2 {
		  font-size: 25px;
	  }
  
	  .massive-impact-bg-shap {
		  padding: 50px 0;
	  }
  
	  .fixing-planet .massive-impact-bg-shap {
		  padding: 50px 0 0 0;
	  }
  
  
	  .footer .footer-input {
		  margin-bottom: 80px;
	  }
	  .footer .social-box ul{
		  bottom: 25px;
	  }
  
	  .footer .social-box ul li a img{
		  width: 30px;
	  }
	  .footer .social-box ul li {
		  margin: 0 10px;
	  }
  }



  /* ------------mouse hover animation------------ */



/* css */
#gradient-canvas{
	z-index: -1;
}

.canvas-top-container{
	z-index: -1;
}

.future-focused-content{
    padding-left: 5vw;
    padding-right: 6.5vw;
}


.footer .social-box ul li{
    height: 30px;
    width: 30px;
    overflow: hidden;
	transform: translate(0, 15px);
}


.footer .social-box ul li a img{
	filter: drop-shadow(0px 2px 5px #00000020);
}
.footer .social-box ul li a img.img-1{
	display: block;
	transform: translate(0, 0);

}

/* .footer .social-box.floating ul li a img.img-1{
	display: none;
	transform: translate(0, 0);

} */

.footer .social-box ul li a img.img-2{
	display: none;
	transform: translate(0, -30px);
}


.footer .social-box.floating ul li a img.img-2{
	display: block;
	transform: translate(0, -30px);
}



@media (max-width: 1370px){
	.future-focused-content{
		padding-left: 0;
		padding-right: 0;
	}
}
/* css */

/*** 09-11-2022 ***/
.meet-network-new{
	padding: 100px 0;
	/* background: url(./assets/img/react-network-banner.png)repeat; */
    background-size: cover;
	overflow: hidden;
	background-color: #0C2D24;
}
  .meet-network-slider-content{
	padding: 0 53px;
	position: relative;
  }

  .meet-network-slider-content:before{
	position: absolute;
	content: '';
	left: 0;
	top: 0;
	bottom: 10%;
	border-left:2px solid #fff;
	bottom: -160px;
  }


  .meet-network-slider .meet-title h2{
	font-family: "Test Söhne Breit";
	font-size: clamp(34px,3.13vw,60px);
	line-height: 120%;
	letter-spacing: 0.04em;
	text-transform: capitalize;
	color: #fff;
	padding-bottom: 33px;
	margin: 0;
  }

  .meet-network-slider .meet-title h2 span{
	background-color: #f8ff31;
	color:#0c2d24;
  }
  .meet-network-slider .meet-title{
	margin-bottom: 65px;
	text-align: left;
  }


  .meet-network-slider .img-box.meet-left img{
	border: 2px solid #2E3939;
	border-radius: 20px;
	/* height: 474px; */
	width: 400px;
	object-fit: cover;
	object-position: 50% 20%;
  }

  .meet-network-slider .meet-network-slider-content .img-box.meet-left{
	text-align: center;
  }
  .meet-network-slider .meet-network-slider-content .meet-right{
	padding: 0 30% 0 10%;
	text-align: left;
  }

  .meet-network-slider .meet-network-slider-content .meet-right h3{
	  font-family: "Test Söhne Breit";
	  font-size: clamp(34px,1.98vw,38px);
	  line-height: 120%;
	  letter-spacing: -0.01em;
	  color: #fff;
	  padding-bottom: 60px;
	  padding-top: 100px;
  }

  .meet-network-slider .meet-network-slider-content .meet-right p{
	font-family: "Proxima Nova";
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 130%;
	color: #fff;
	text-transform: initial;
	max-width: 90%;
  }

  .meet-network-new .section {
	width: 100%;
	min-height: 100vh;
	font-size: 36px;
	text-align: center;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	font-weight: bold;
  }


  .scroll {
	display: flex;
	flex-wrap: nowrap;
	overflow: hidden;
	scroll-behavior: smooth;
  }

  .item {
	  min-width: 100vw;
  }


  @media(max-width: 1370px){
	.meet-network-slider .meet-network-slider-content .meet-right {
		padding: 0 10% 0 10%;
		text-align: left;
	}
  }

  @media(max-width: 767px){
	.meet-network-slider .meet-network-slider-content .meet-right {
		padding: 50px 50px 0 0;
	}
  }



  @media(max-width: 576px){
	.meet-network-slider .meet-network-slider-content .meet-right {
		padding: 50px 0 0 0;
	}

	.meet-network-slider-content {
		padding: 0px 25px;
	}

	.meet-network-slider .meet-network-slider-content .meet-right h3{
		font-size: 22px !important;
	}
  }

/*** 10-11-2022 ***/
  .more-react{
	padding: 74px 0 150px 0;
  } 
 
  .more-react h2{
    padding-bottom: 2em;
    font-family: "Test Söhne Breit";
    font-size: clamp(34px,4.17vw,80px) !important;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.04em;
    color: #0C2D24;
  }

  .future-focused h2 span,
  .more-react h2 span{
	font-style: normal;
	font-weight: 400;
	font-size: 45px;
    line-height: 90px;
	text-align: center;
	letter-spacing: 0.04em;
	color: #0C2D24;
  }

  .more-react .accordion::after {
	background: url(./assets/img/plus-icon.svg)no-repeat;
	transform: scale(.7) !important;
	content: '';
    display: block;
    width: 30px;
    height: 30px;
    float: right;
  }
  .more-react .accordion.accordion.active::after {
	background: url(./assets/img/minus-icon.svg)no-repeat;
	content: '';
    display: block;
    width: 30px;
    height: 30px;
    float: right;
  }

  .more-react .accordion {
	font-family: "Proxima Nova";
	background-color:transparent;
	cursor: pointer;
	padding:18px 0;
	width: 100%;
	border: none;
	text-align: left;
	outline: none;
	transition: 0.4s;
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 120%;
	letter-spacing: -0.01em;
	color: #06C290;
  }
  
  
  .more-react .panel {
	padding: 0;
	display: none;
	background-color: white;
	overflow: hidden;

  }


  .more-react .panel p{
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 130%;
	color: #0C2D24;
	padding: 20px 0 40px 0;
  }


  @media(max-width: 991px){
	.more-react .container{
		padding-right: 0;
		padding-left: 0;
	}

	.future-focused .title {
		padding-top: 0;
	}
	.future-focused h2 span,
	.more-react h2 span {
		font-size: 28px;
		line-height: 60px;
		
	}

  }



  @media(max-width: 576px){
	.more-react .accordion{
		font-size: 24px;
	}
  }


  .climate-capital-logo{
	max-width: 265px !important;
  }


  @media(max-width: 992px){
	.future-focused .container {
		max-width: 95% !important;
	}

	.massive-impact .massive-impact-content .massive-impact-left {
		margin: 25px 0 40px 0;
		text-align: center;
	}

	.fixing-planet .massive-impact-right {
		padding: 20% 0 0 0;
	}
  }


  .more-react .panel.smoothSlide{
	opacity: 1;
    height: auto;
	display: block;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 2s;
  }

@-webkit-keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}
@keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}

.more-react .panel{
	transition: opacity 2s ease-out;
    opacity: 0;
    height: 0;
	display: none;
    -webkit-animation: fadeOut 1s;
    animation: fadeOut 2s;
  }

  @-webkit-keyframes fadeOut {
    from { opacity: 1; }
      to { opacity: 0; }
	}
	@keyframes fadeOut {
		from { opacity: 1; }
		to { opacity: 0; }
	}


	/***11.11.2022*****/
	.massive-impact-content{
		background-color: #fff;
	}

	.massive-impact-right {
		padding: 0 50px 0 60px;
	}

	@media(max-width: 1200px){
		.massive-impact-right {
			padding: 0 0 0 25px;
		}

		.top-banner {
			background-size: initial;
		}
	}

	@media(max-width: 991px){
		.canvas-top-container{
			z-index: 5 !important;
		}

		.navbar-toggler{
			z-index: 10 !important;
		}
	}
	


	@media(max-width: 767px){
		.massive-impact-right {
			padding: 0;
		}

		.top-banner .top-banner-left {
			padding-top: 40px;
		}

		.top-banner .top-banner-left h1 {
			font-size: 70px;
		}

		.footer h2 {
			font-size: 62px;
			line-height: initial;
		}

		/* .meet-network-slider .meet-network-slider-content .meet-right h3{
			padding-top: 0;
		}
		.meet-network-slider .img-box.meet-left img {
			width: 350px;
		} */
	}

	@media(max-width: 576px){
		/* .header .navbar-brand img {
			width: 215px;
		} */

		.top-banner .top-banner-left h1{
			font-size: 40px !important;
			line-height: 58px !important;
		}
		.footer .container{
			max-width: 100% !important;
		}

		.footer h2 {
			font-size: 32px;
		}
		
	}


	@media(max-width: 375px){
		.top-banner .top-banner-left h1{
			font-size: 30px !important;
			line-height: 50px !important;
		}

		.header .navbar-brand img{
			width: 200px;
		}
		.top-banner .top-banner-left p {
			font-size: 24px;
		}
	}

	a.navbar-brand {
	    z-index: 999999;
	}
	.collapse.show {
		z-index: 999999;

	}
	.future-focused-parent{
		
		position: relative;
		background-position: 0 -265px;
	}
	.massive-impact-marquee .marquee-text {
		font-family: "Test Söhne Breit";
		/*font-size: 45px;*/
		font-size: 28px;
		line-height: 120%;
		text-align: center;
		letter-spacing: 0.04em;
		text-transform: uppercase;
		border: 2px solid rgba(12, 45, 36, 0.75);
		/*padding: 12px;*/
		padding: 22px;
		margin-bottom: 116px;
		overflow-y: hidden!important;
	}

	.globe-img{
		opacity: 0;
	}


	/* @media(min-width: 1300px){
		.top-banner-bg{
			background: url(./assets/img/Globes.png)no-repeat;
			background-position: 100% -75px;
			background-size: 850px 905px;
		}
	} */
/* 
	@media(max-width: 1300px){
		.top-banner-bg{
			background: url(./assets/img/Globes.png)no-repeat;
			background-position: 100% -75px;
			background-size: 850px 905px;
			
		}
	} */



	@media(max-width: 1200px){
		.top-banner-bg{
			background: none;
		}

		.globe-img{
			opacity: 1;
		}

		.meet-network-slider .meet-network-slider-content .meet-right p{
			font-size: 18px;
		}
	}
	/***11.11.2022*****/


	

	/* Meet network slider */

	.section-meet {
		padding: 20px;
		box-sizing: border-box;
	}
	  
	.vertical-center {
		display: table;
		height: 100%;
		width: 100%;
	}
	  
	.vertical-center__inner {
		display: table-cell;
		vertical-align: middle;
	}
	  
	.section--full {
		text-align: center;
	}

	
	.section--vertical {
		min-height: 100vh;
		position: relative;
	}

	.block-list {
		white-space: nowrap;
	}

	.block-list__item {
	width: 250px;
	height: 100vh;
	min-height: 600px;
	display: inline-block;
	white-space: normal;
	padding-right: 20px;
	}

	.block-list__item-inner {
	/* background: #eee; */
	width: 100%;
	height: 76vh;
	}

	.block-list__item:first-child {
	margin-left: 0;
	}

	/* Meet network slider */


	/**********17.11.2022**************/

	@media(max-width: 992px){
		.meet-network-slider .meet-network-slider-content .meet-right h3{
			font-size: 28px;
			padding-bottom: 30px;
		}

		.meet-network-slider-content {
			padding: 0 25px;
		}

		.meet-network-slider .meet-network-slider-content .meet-right{
			padding: 0 15px;
		}
	}

	@media(max-width: 767px){
		.meet-network-slider .img-box.meet-left img{
			height: auto !important;
			max-width: 80%;
		}

		.meet-network-slider .meet-title h2{
			padding-bottom: 10px;
			font-size: 32px;
		}

		.meet-network-slider .meet-title img{
			width: 44px;
		}


		.meet-network-slider .meet-title {
			margin-bottom: 20px;
			text-align: left;
		}
		.meet-network-slider .meet-network-slider-content .meet-right h3{
			padding-bottom: 20px;
			padding-top: 0;
			margin: 0;
		}

		.meet-network-slider .meet-network-slider-content .meet-right p br{
			display: none;
		}
		.meet-network-slider .block-list__item{
			padding-right: 0;
		}


		.meet-network-slider .meet-network-slider-content .meet-right {
			padding: 25px 0 0 0;
			width: 90%;
			margin: 0 auto;
		}

		.meet-network-slider .meet-network-slider-content .meet-right p{
			font-size: 17px;
		}

		.meet-network-slider .img-box.meet-left img {
			height: 475px !important;
			max-width: 80%;
		}

	}

	@media(max-width: 576px) {
		
		.meet-network-slider .img-box.meet-left img {
			height: 220px !important;
			max-width: 100%;
		}

		.meet-network-slider .meet-network-slider-content .meet-right p {
			font-size: 16px;
			max-width: 100%;
		}


		@supports (-webkit-touch-callout: none) {
			.meet-network-slider .meet-network-slider-content .meet-right p {
				font-size: 13px !important;
				max-width: 100%;
			}
		}


		@media (max-width: 374px) {
			.meet-network-slider .meet-network-slider-content .meet-right p {
				font-size: 15px;
			}
		}
	}

	
	/* 01.05.2023 new-css */


	.future-focused .energy-together-content .left-contnt ul{
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
	}


	.future-focused .energy-together-content .left-contnt ul a.btn{
		margin: 0 7px;
	}

	.btn-default.get-started-btn{
		background: #0C2D24;
		border-radius: 200px;
		font-family: "Proxima Nova";
		font-size: 18px;
		line-height: 120%;
		text-transform: uppercase;
		padding: 24px 70px 20px 70px;
		letter-spacing: 0.04em;
		border: none;
		margin-bottom: 56px;
		color: #fff;
		transition: ease-in-out 0.3s;
		z-index: 999;
		position: relative;
	}

	.btn-default.get-started-btn:hover{
		background: linear-gradient(90deg, #06C290 0%, #95E5D0 33.85%, #E1F5C7 66.67%, #F8FF31 98.96%);
		color: #0C2D24;
		transition: ease-in-out 0.3s;
	}

	.btn-primary.btn-download{
		background: linear-gradient(90deg, #06C290 0%, #95E5D0 33.85%, #E1F5C7 66.67%, #F8FF31 98.96%);
		border-radius: 200px;
		color: #0C2D24;
		font-family: "Test Söhne Breit";
		font-size: 15px;
		border: none;
		padding: 24px 70px 20px 70px;
		font-size: 18px;
		line-height: 120%;
	}

	.navbar .navbar-nav .nav-item .nav-link.download-btn{
		margin: 3px 0px;
	}

	.navbar .navbar-nav .nav-item .nav-link.btn{
		background: linear-gradient(90deg, #06C290 0%, #95E5D0 33.85%, #E1F5C7 66.67%, #F8FF31 98.96%);
		border-radius: 200px;
		color: #0C2D24;
		font-family: "Test Söhne Breit";
		font-size: 15px;
		border: none;
		padding: 15px 28px;
	}

	.navbar .navbar-nav .nav-item .nav-link.download-btn{
		border: none !important;
	}

	.top-banner .top-banner-left .powered-by{
		letter-spacing: 0.04em;
		text-transform: uppercase;
		color: #2E3939;
		font-size: 20px;
		line-height: 120%;
		font-family: "Test Söhne Breit";
		font-weight: 400;
		text-decoration: none;
	}

	.change-everything .change-everything-right{
		padding: 32% 8% 10% 8%;
	}


	.change-everything .change-everything-right h3{
		font-family: "Test Söhne Breit";
		/* font-size: 42px; */
		font-size: clamp(34px, 3.13vw, 60px);
		line-height: 140%;
		/* line-height: 56px; */
		letter-spacing: 0.04em;
		text-transform: capitalize;
		color: #0C2D24;
		padding-bottom: 50px;
	}

	.change-everything .change-everything-right h3 span{
		background-color: #f8ff31;
		padding: 0 10px 0 0;
	}

	.change-everything .change-everything-right p,
	.change-everything .change-everything-right a{
		font-family: "Proxima Nova";
		font-style: normal;
		font-weight: 400;
		font-size: 22.5px;
		line-height: 130%;
		color: #2E3939;
		max-width:80%;
	}

	.change-everything .change-everything-right a{
		border-bottom: 2px solid #000;
		text-decoration: none;
		z-index: 999;
		position: relative;
	}

	.change-everything{
		position: relative;
		padding-top: 20%;
		overflow: hidden;
	}

	.change-everything .animation-2{
		height: 500px;
		width: 100vw;
		border: none;
		position: absolute;
		left: 0;
		right: 0;
		top: auto;
		bottom: 60%;
		z-index: -1;
	}


	.energy-together-content .left-contnt{
		padding-top: 30px;
	}
	.energy-together-content .left-contnt p{ 
		font-family: "Proxima Nova";
		font-style: normal;
		font-weight: 400;
		font-size: 22.5px;
		line-height: 120%;
		color: #0C2D24;
		padding: 0 100px 50px 0;
		max-width: 525px;
	  }


	  /* .energy-together-content .left-contnt h3{
		color: #0C2D24;
		background-color: #f8ff31;
		display: inline-block;
		letter-spacing: 0.04em;
		line-height: 54px;
		font-size:45px;
		margin-bottom: 67px;
	  } */

	  .future-focused p.learn-more{
			text-align: center;
			line-height: 42px;
			margin-top: 100px;
	  }

	  .future-focused p.learn-more a{
		color: #fff;
		text-align: center;
		letter-spacing: 0.04em;
		font-size: 35px;
		font-family: "Test Söhne Breit";
		font-weight: 400;
		text-decoration: none;
		z-index: 999;
		position: relative;
	  }


	  .future-focused p.learn-more a span{
		background-color: #f8ff31;
		color: #0c2d24;
	  }


	  .backup-plan{
		background: #0C2D24;
		padding: 100px 0;
	  }

	  .backup-plan .backup-plan-left{
		padding-top:150px;
	  }

	  .backup-plan .backup-plan-left h3{
			font-family: "Test Söhne Breit";
			font-size: 42px;
			line-height: 56px;
			letter-spacing: 0.04em;
			text-transform: capitalize;
			color: #fff;
			padding-bottom: 50px;
	  }

	  .backup-plan .backup-plan-left h3 span{
		background-color: #f8ff31;
		color: #0c2d24;
	  }

	  .backup-plan .backup-plan-left p{
		font-family: "Proxima Nova";
		font-style: normal;
		font-weight: 400;
		font-size: 22.5px;
		line-height: 130%;
		color: #fff;
		max-width: 515px;
	  }

	  .backup-plan .backup-plan-left a{
		border-bottom: 2px solid #fff;
		text-decoration: none;
		font-family: "Proxima Nova";
		font-style: normal;
		font-weight: 400;
		font-size: 22.5px;
		line-height: 130%;
		color: #fff;
		z-index: 999;
		position: relative;
	  }

	  .featured{
		padding: 100px 0 125px 0;
		position: relative;
	  }
	  .featured h2{
		font-family: "Test Söhne Breit";
		font-size: clamp(34px, 3.13vw, 60px);
		text-align: center;
		padding-bottom: 100px;
	  }



	  .featured ul li a{
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		transition: all .5s;
	  }
	  .featured ul li{
		border-bottom: 2px solid #0C2D24;
		padding:5px 0;
	}

	.featured ul li a{
		font-family: "Test Söhne Breit";
		font-size: clamp(34px,1.98vw,38px);
		text-decoration: none;
		color: #2E3939;
		position: relative;
		padding: 0 15px;
	}	


	.featured ul li a img{
		height: 25px;
		width: 25px;
	}
	  .featured ul li a:after{
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		margin: auto;
		width: 100%;
		height: 1px;
		content: '.';
		color: transparent;
		background: #F8FF31;
		visibility: none;
		opacity: 0;
		z-index: -1;
		transition: all .5s;
	  }

	  .featured ul li a:hover:after{
		opacity: 1;
		visibility: visible;
		height: 100%;
		transition: all .5s;
	}


	.featured .changing-world h2{
		padding: 100px 0 0 0;
	}

	.changing-world img{
		margin: 0 50px;
	}

	.changing-world .marquee-text{
		border: none;
		background-color: transparent;
	}


	.changing-world .logo-lerer{
		height: 60px;
	}

	.changing-world .logo-lattice{
		height: 50px;
	}

	.changing-world .logo-dcg{
		height: 80px;
	}
	.changing-world .logo-coinshares1{
		height: 50px;
	}
	.changing-world .logo-vaynerfund1{
		height: 60px;
	}

	.changing-world .logo-allegory1{
		height: 55px;
	}
	.changing-world .logo-gaingels1{
		height: 60px;
	}

	.changing-world .climate-capital1{
		height: 90px;
	}

	.footer .footer-input .wailist-input{
		padding: 6px 6px 6px 12px;
	}

	.footer .footer-input .wailist-input .btn{
		background: linear-gradient(90deg, #06C290 0%, #95E5D0 33.85%, #E1F5C7 66.67%, #F8FF31 98.96%) ! important;
		color: #0C2D24 !important;
		transition: ease-in-out 0.3s;
		font-family: "Proxima Nova";
		font-size: 18px;
		line-height: 120%;
		text-transform: uppercase;
		padding: 24px 70px 20px 70px;
		border: none !important;
	}


	.download-app{
		text-align: right;
	}

	.download-app p{
		font-weight: 400;
		font-size: 22.5px;
		line-height: 120%;
		color: #fff;
		padding-bottom: 30px;
		margin: 0;
	}

	.download-app ul li a img{
		margin:0 10px;
	}

	.footer_logo{
		font-family: "Proxima Nova";
		font-weight: 400;
		font-size: 20px;
		color: #fff;
	}

	@media(max-width:1500px){
		.change-everything .change-everything-right {
			padding: 32% 8% 25% 8%;
		}
		.change-everything .change-everything-right h3{
			max-width: 100%;
		}

		.future-focused h2{
			font-size: 52px;
		}

		.energy-together-content .left-contnt p{
			padding: 0 65px 85px 0;
		}

		.backup-plan .backup-plan-left p{
			max-width: 88%;
		}


	}

	@media(max-width:1200px){
		.backup-plan .backup-plan-left h3{
			max-width: 100%;
		}

		.backup-plan .backup-plan-left {
			padding-top: 80px;
		}

		.meet-network-slider .meet-network-slider-content .meet-right h3{
			padding-top: 167px;
		}


		.future-focused h2 {
			font-size: 42px;
			padding-bottom: 40px;
		}

		.backup-plan .backup-plan-left h3{
			font-size: 34px;
		}

		.change-everything .change-everything-right p, 
		.change-everything .change-everything-right a,
		.energy-together-content .left-contnt p,
		.backup-plan .backup-plan-left p,
		.backup-plan .backup-plan-left a{
			font-size: 20px;
		}
		
	}

	@media(max-width:992px){
		.top-banner .top-banner-left h1{
			font-size: 50px;
			line-height: 80px;
		}

		.change-everything .row{
			flex-direction: column;
		}

		.change-everything .row .col-md-6{
			max-width: 100%;
		}

		.change-everything .change-everything-right {
			padding: 10% 8% 10% 8%;
		}

		.change-everything .animation-2{
			bottom: 75%;
		}
		
		.energy-together-content {
			padding: 100px 30px;
		}

		.future-focused h2{
			font-size: 36px;
		}

		.energy-together-content .left-contnt p {
			padding: 0 15px 60px 0;
		}

		.backup-plan .row{
			flex-direction: column-reverse;
		}
		
		.backup-plan .row .col-md-6{
			max-width: 100%;
		}

		.backup-plan .backup-plan-right{	
			text-align: center;
		}

		.backup-plan .backup-plan-right img{
			width: 60%;
		}

		.change-everything .change-everything-right h3 {
			max-width: 70%;
		}
	}

	@media(max-width:767px){
		.change-everything .change-everything-right {
			padding:10% 0;
		}

		.change-everything .change-everything-right p, .change-everything .change-everything-right a{
			max-width: 100%;
		}
		.changing-world .marquee img{
			width: 90px;
			height: auto;
			margin: 25px;
		}

		.download-app ul{
			display: flex;
			justify-content: flex-end;
			align-items: center;
			width: 100%;
		}

		.footer .footer-input .wailist-input .btn{
			padding: 16px 70px 10px 70px;
		}

		.meet-network-slider .meet-network-slider-content .meet-right h3 {
			padding-top: 0;
		}
	}
	@media(max-width:576px){
		.backup-plan .backup-plan-right img {
			width: 100%;
		}
		.featured ul li a {
			font-size: 20px;
		}

		.featured ul li a img {
			height: 20px;
			width: 20px;
		}

		
		.download-app ul li img{
			height: auto;
			/* width: 50%; */
		}


		/* .download-app ul li img.app_store{
			height: 45px;
		}

		.download-app ul li img.play_store{
			height: 65px;
		} */

		.backup-plan .backup-plan-left h3{
			font-size: 28px;
			line-height: initial;	
		}

		.backup-plan .backup-plan-left p {
			max-width: 100%;
		}

		.btn-default.get-started-btn{
			padding: 0;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			line-height: 56px;
		}

		.energy-together-content .left-contnt p {
			padding: 0 0 40px 0;
		}
		.energy-together-content .left-contnt h3{
			font-size: 28px;	
			margin-bottom: 30px;		
		}

		.energy-together-content {
			padding: 15px 15px 5px 15px;
		}

		.future-focused h2 {
			font-size: 28px;
		}

		.change-everything .change-everything-right h3 {
			max-width: 100%;
			font-size: 28px;
		}

	}
	
	/* 01.05.2023 new-css */

	@media(max-width: 1750px){
        .footer h2{
            font-size: 40px;
            line-height: initial;
        }

        .footer .footer-input .wailist-input{
            max-width: 100%;
        }
    }

	@media(max-width: 767px){
		.top-banner-bg .error{
			padding: 12px 12px 12px 20px !important;
			text-align: left;
		}
	}
	@media(max-width: 576px){
		.footer h2 {
			font-size: 28px;
			line-height: initial;
		}

		.footer .footer-input .wailist-input{
			background: transparent;
		}
	}
	


	.top-banner .change-everything-left{
		padding: 200px 0 100px 0;
	}

	@media(max-width: 991px){
		.top-banner .change-everything-left{
			padding: 200px 0 0 0;
		}	
	}


	@media(max-width: 576px){
		.top-banner .change-everything-left{
			padding: 100px 0 0 0;
		}	
	}


	.footer_logo a{
		font-family: "Proxima Nova";
		font-weight: 400;
		font-size: 20px;
		color: #fff !important;
		text-decoration: none !important;
		margin: 0 0 0 22px;
	}

		.navbar .navbar-nav .footer-link{
			display: none;
		}

		@media(max-width: 768px){


			.navbar .navbar-nav .nav-item .footer-link{
				display: block;
			}

			.footer_logo a{
				display: none;
			}
		}

	/*18.03.2023*/
	@media (max-width: 991px){
		.footer_logo .footer-link{
			display: none;
		}
		.navbar .navbar-nav .footer-link.mob-footer-link {
			display: block;
		}
		.energy-together-content .left-contnt h2 span{
			font-size: 30px;
		}
	}
	/*18.03.2023*/

	/*.03.2023*/
	
	
	.download-app ul li a img {
		margin: 0 0 0 20px;
	}
	.download-app ul{
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	@media (max-width: 400px){
		.app-store .app-store-discription-box .app-store-box{
			width: 100%;
			text-align: center;
		}
		.app-store .app-store-discription-box .app-store-box a{
			display: block;
			margin: 10px;
		}

		.download-app{
			text-align: left;
		}

		.download-app ul{
			flex-direction: column;
		}

		.download-app ul li{
			width: 100%;
			text-align: left;
		}
		.download-app ul li a img{
			width: 40% !important;
			margin: 0 auto 20px auto !important;
		}

		.download-app ul li a img {
			margin: 0;
		}
	}
	