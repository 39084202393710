.shop-section,
.technical-specifications{
    padding: 50px 0;
}

.app-store{
    padding: 85px 0;
}

.shop-section,
.technical-specifications{
    background: #F9F9F9;
}

.shop-section .shop-title{
    margin-bottom: 45px;
}
.shop-section .shop-title h2{
    font-family: "Proxima Nova" !important;    
    font-weight: 700;
    font-size: 54px;
    line-height: 120%;
    color: #0C2D24;
}

.shop-section .shop-title p{
    font-family: "Proxima Nova" !important;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 160%;
    color: #2E3939;
}

.shop-section .product-detail-box h3{
    font-family: "Proxima Nova" !important;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 120%;
    color: #0C2D24;
}


.shop-section .product-detail-box p{
    font-family: "Proxima Nova" !important;
    font-style: normal;
    font-weight: 400;
    font-style: normal;
    font-size: 34px;
    line-height: 120%;
    color: #0C2D24;
}


.shop-section .btn-primary{
    font-family: "Proxima Nova" !important;
    background: #06C290 !important;
    border-color: #06C290 !important;
    border-radius: 64px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #FCFCFF;
    width: 351px;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    box-shadow: 0px 12px 42px -4px rgba(24,39,75,0.12) !important;
}



.technical-specifications h3{
    font-family: "Proxima Nova" !important;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 140%;
    letter-spacing: 1px;
    color: #0C2D24;
    margin: 0;
    padding-bottom: 80px;
}

.technical-specifications h4{
    font-family: "Proxima Nova" !important;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    color: #0C2D24;
    margin: 0;
    padding-bottom: 30px;
}

.technical-specifications h5,
.technical-specifications p{
    font-family: "Proxima Nova" !important;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #2E3939;
    margin: 0;
}

.technical-specifications p{
    font-family: "Proxima Nova" !important;
    font-weight: 400;
    margin:0;
    padding-bottom: 30px;
    max-width: 200px;
}

.technical-specifications ul li{
    list-style: disc;
    margin-left: 20px;
}

.technical-specifications ul li {
    font-family: "Proxima Nova" !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #2E3939;
    text-decoration: none;
}



.app-store .app-store-discription-box{
    max-width: 572px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}


.app-store .app-store-discription-box h6{
    font-family: "Proxima Nova" !important;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 130%;
    letter-spacing: 1px;
    color: #0C2D24;
    margin-bottom: 30px;
}

.app-store .app-store-discription-box p{
    font-family: "Proxima Nova" !important;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    color: #2E3939;
    margin-bottom:40px;
}

.app-store .app-store-discription-box .app-store-box a{
    font-family: "Proxima Nova" !important;
    margin-right: 30px;
}

.shop-section .product-detail-box{
    margin-top: 15px;
}

.app-store .app-store-discription-box .app-store-box img{
    width: 160px;
    /* height: 50px; */
}

@media (max-width:1200px){
    

    .app-store .app-store-discription-box h6{
        font-size: 24px;
    }
}

@media (max-width:991px){
    .shop-section .btn-primary{
        width: 277px;
    }

    .app-store .app-store-discription-box .app-store-box a{
        margin-right: 20px;
    }

    .app-store .app-store-discription-box .app-store-box img {
        width: 125px;
        height: 40px;
    }
}


@media (max-width:576px){

    .shop-section .shop-title{
        text-align: center;
    }
    .technical-specifications{
        padding-top: 63px;
    }
    .shop-section .shop-title h2{
        font-size: 34px;
    }

    .shop-section .shop-title p,
    .technical-specifications h5, 
    .technical-specifications p{
        font-size: 15px;
    }


    .shop-section .product-detail-box h3,
    .shop-section .product-detail-box p{
        font-size: 24px;
    }

    .shop-section .btn-primary{
        width: 209px;
    }

    .technical-specifications h3{
        font-size: 28px;
        padding-bottom: 45px;
    }

    .technical-specifications h4{
        font-size: 20px;
        padding-bottom: 22px;
    }
    .app-store .app-store-discription-box h6{
        font-size: 20px;
    }

    .app-store .app-store-discription-box p{
        font-size: 15px;
    }

}

