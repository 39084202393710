.top-banner.top-banner-2{
    background: none;
    background-size: cover;
} 

.faq-more-react{
    /* background: url(../../assets/img/faq-banner.png)no-repeat; */
    background-size: cover;
}
.faq-more-react .accordion.border-top{
    border-top:1px solid rgba(12, 45, 36, 0.35)
}

.faq-more-react .accordion.border-bottom{
    border-bottom:1px solid rgba(12, 45, 36, 0.35)
}

.faq-more-react{
    padding: 74px 0 150px 0;
} 

.faq-more-react h2{
    padding-bottom: 2em;
    font-family: "Test Söhne Breit";
    font-size: clamp(34px,4.17vw,80px) !important;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.04em;
    color: #0C2D24;
}

.faq-more-react h2 span{
    font-style: normal;
    font-weight: 400;
    font-size: 45px;
    line-height: 90px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #0C2D24 !important;
}

.faq-more-react .accordion::after {
    background: url(../../assets/img/plus-icon-black.svg)no-repeat;
    transform: scale(.7) !important;
    content: '';
    display: block;
    width: 30px;
    height: 30px;
    float: right;
    z-index: 999999;
}
.faq-more-react .accordion.accordion.active::after {
    background: url(../../assets/img/minus-icon-black.svg)no-repeat;
    content: '';
    display: block;
    width: 30px;
    height: 30px;
    float: right;
}

.faq-more-react .accordion {
    font-family: "Proxima Nova";
    background-color:transparent;
    cursor: pointer;
    padding:18px 0;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    transition: 0.4s;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.01em;
    color: #0C2D24;
}


.faq-more-react .panel {
    padding: 0;
    display: none;
    background-color:transparent;
    overflow: hidden;
}


.faq-more-react .panel p{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    color: #0C2D24;
    padding: 20px 0 40px 0;
}

/* @media(max-width: 1370px){
    .future-focused .card {
        min-height: 500px;
    }
}

@media(max-width: 1200px){
    .future-focused .card {
        min-height: 400px;
    }
} */

@media(max-width: 991px){
    .faq-more-react .container{
        padding-right: 0;
        padding-left: 0;
    }

    .faq-more-react h2 span {
        font-size: 28px;
        line-height: 60px;
    }


}



@media(max-width: 576px){
    .faq-more-react .accordion{
        font-size: 24px;
    }
}


@media(max-width: 992px){

   
}


.faq-more-react .panel.smoothSlide{
    opacity: 1;
    height: auto;
    display: block;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 2s;
}

    @-webkit-keyframes fadeIn {
    from { opacity: 0; }
        to { opacity: 1; }
    }
    @keyframes fadeIn {
    from { opacity: 0; }
        to { opacity: 1; }
    }

.faq-more-react .panel{
    transition: opacity 2s ease-out;
    opacity: 0;
    height: 0;
    display: none;
    -webkit-animation: fadeOut 1s;
    animation: fadeOut 2s;
}

    @-webkit-keyframes fadeOut {
    from { opacity: 1; }
        to { opacity: 0; }
    }
    @keyframes fadeOut {
        from { opacity: 1; }
        to { opacity: 0; }
    }