 .canvas-top-container{
	/*position: relative;*/
	width: 100%;
	height:100vh;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	/*background: #001300;*/
	overflow: hidden;
	animation: animateColor 3s linear infinite;
	z-index: 1;
    position: absolute;
    height: 100%;
	
}

@keyframes animateColor{
	0%
	{
		filter: hue-rotate(360deg);
	}
	100%
	{
		filter: hue-rotate(360deg);
	}
}

.canvas-top-container span
{
	position: relative;
	display: block;
	width: 40px;
	height: 40px;
	/*background: #f00;*/
}

.canvas-top-container span::before{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 110%;
	height: 160%;
	background: #8DFBC8;
	border-radius: 50%;
	transition: 2s;
	transform: scale(0);
	/* background: #8DFBC850; */
	box-shadow:  0px 0px 20px 20px #8DFBC850,
	0px 0px 20px 20px #8DFBC850,
	0px 0px 20px 20px #8DFBC850,
	0px 0px 20px 20px #8DFBC850,
	0px 0px 20px 20px #8DFBC850,
	0px 0px 20px 20px #8DFBC850;
}


.canvas-top-container span:hover::before{
	transition: 0s;
	transform: scale(1);
}